@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.space-mono-regular {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.space-mono-bold {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: normal;
}

.space-mono-regular-italic {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: italic;
}

.space-mono-bold-italic {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: italic;
}
.fw-bold {
  font-family: "Space Mono", monospace;
  font-weight: 700;
}

body {
  font-family: "Space Mono", monospace; 
}

@font-face {
  font-family: 'Grandis Extended Bold';
  src: url('../fonts/Grandis\ Extended\ Bold.otf') format('woff2'),
  /* Additional font face properties like font-weight and font-style can also be specified here */
}

@font-face {
  font-family: 'Grandis Extended Thin';
  src: url('../fonts/Grandis\ Extended\ Thin.otf') format('woff2'),
  /* Additional font face properties like font-weight and font-style can also be specified here */
}


.outline-font {
  -webkit-text-stroke: 1.5px #0E0F0F;
  color: transparent !important;
}
.outline-light-font {
  -webkit-text-stroke: 1.5px #ffffff;
  color: transparent !important;
}



$primary-color:#B3F700;

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Grandis Extended Bold";
}
p {
  font-family: "Space Mono", monospace;
  font-size: 18px;
}

.color-primary {
color: $primary-color;
}
.text-large {
  font-size: 75.66px;
}
.text-large1 {
  font-size: 60px;
}
.text-large-md {
  font-size: 40px;
}
.bg-gray {
  background-color: #F1F1F1;
}
.shadow-25 {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.content-txt {
  color: #0E0F0F;
}

.home-section {
  padding: 100px 20px 150px;
  width: 100%;
  z-index: 1;
  position: relative;
}
.navbar {
  background-color:#0E0F0F;
  padding: 20px 10px;
  .nav-link {
    text-decoration: none;
    color:#ffffff;
    position: relative;
    letter-spacing: 1px;
    min-width: 50px;
    font-size: 16px;
    font-weight: 700;
    line-height: 23.7px;
    text-align: center;

  &:hover {
    color:#B3F700;
  }
}

}

.custom-navbar {
  width: 100%;
  .navbar-brand {
    width:130px;
   margin-right: 100px;
  }
}

.navbar-nav {
  gap: 20px;
}

.navbar-nav .nav-link.active {
  color:$primary-color;
}

.banner {
  background:#000000;
  min-height:767px;
  position: relative;
  overflow: hidden;
}
.banner-rhs-img {
  background:url(../image/banner/hand.svg);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  min-height:767px;
}
.banner-lhs {
  padding-left: 50px;
}
.banner-sm-txt {
  font-size: 16px;
  line-height: 23.7px;
}
.sq-btn.btn {
  background-color:#B3F700;
  border: 3px solid transparent;
  color:#0E0F0F;
  text-transform: uppercase;
  border-radius: 8px;
  padding:8px 24px;
  overflow: hidden;
  font-weight: 700;
  font-size:16px;
  font-family: "Space Mono", monospace;
  transition:
    all        1.2s,
    border     0.1s 1s,
    box-shadow 0.1s 1s;
    &:hover {
      text-indent: 0;
      background-color: #fff !important;
      color:#0E0F0F;
      border: 3px solid #B3F700;
      box-shadow: 3px 3px 2px rgba(black, 0.15);
    
    }
}
.secondary-btn.btn {
  background-color:#0E0F0F;
  color:#ffffff;
  text-transform: uppercase;
  border-radius: 8px;
  letter-spacing: 6%;
  padding:8px 24px;
  overflow: hidden;
  font-weight: 700;
  font-size:16px;
  font-family: "Space Mono", monospace;
  transition:
    all        500ms,
    border     500s,
    box-shadow 500s;
    transition-duration: 500ms;
    &:hover {
      text-indent: 0;
      background-color: #B3F700 !important;
      color:#0E0F0F;
    
    }
}
.float {
  animation-name: float;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes float {
	50%{
    transform: translateY(30px);
  }
}
.banner-content {
  padding: 130px 0 0px;
}
// Banner Text Spark

@keyframes float {
  0%,
  100% {
    transform: translateY(0) skewX(-8deg);
  }
  50% {
    transform: translateY(-10px) skewX(-8deg);
  }
}
// About us css
.main{
  background: #10141F;
  padding: 60px 0 0;
}

// silder css


.footer {
  background:#0E0F0F;
  position: relative;
  z-index: 10;
  padding:24px;
  color: #fff;
  a {
    color: #B3F700;
    text-decoration: none;
  }
}

.social img {
  padding: 10px;
}
.insta {
  width: 5%;
}
.social p {
  color: #fff;
}
.welcome-txt {
  font-size: 60px;
  line-height: 75px;
}

// Radar Anim
@mixin sizer($width, $height: $width) {
	height: $height;
	width: $width;
}

%indicator-pulse {
	animation: glow 1s linear infinite;
	background-color: #f00;
	border-radius: 50%;
	content: "";
	filter: blur(2px);
	@include sizer(10px);
	position: absolute;
}
.dark-bg {
  background-color: #000000;
}

body {
	background-color:#ffffff;
	min-height: 100vh;
}
.radar-wrap {
  position: absolute;
  top: 24%;
  z-index: 2;
  height: 230px;
  width: 230px;
  left: 0;
}

.token-list {
  padding-top: 100px;
  li {
    position: relative;
    margin-bottom: 50px;
    align-items: center;
    &:after {
      content: "";
      border-bottom: 1px dashed #515151;
      height: 1px;
      width:calc(100% - 170px);
      position: absolute;
      top: 16px;
      right:60px;
    }
    &:hover {
      .token-val, .token-label {
        color: #B3F700;
      }
    }
  }

}
.token-val, .token-label {
  font-family: Space Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.73px;
  color: #515151;
}
.token-row {
  &:hover {
    .dot {
      background:#B3F700;
    }
    .token-val, .token-label {
      color: #B3F700;
    }
  }
}

.dot {
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  background: #ffffff;
  border-radius: 50%;
  margin: 6px;
}

.network-heading {
  position: relative;
  margin-bottom: 64px;
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 81%;
    background-color: #B3F700;
    position: absolute;
    top: 5px;
  }
  .n-label, .n-title {
    font-family: "Grandis Extended Bold";
    font-size: 16px;
    font-weight: 700;
    line-height: 20.48px;
    padding-left:16px;
  }
  .n-label {
    padding-bottom: 12px;
    color: #333333;
  }

}
.btm-txt {
  margin-top: 54px;
  display: inline-block;
  margin-left: 16px;
  font-family: 'Grandis Extended Bold';
}

.hover-txt {
  &:hover {
    color: #B3F700;
    .token-val {
      color: #B3F700;
    }
    .outline-light-font {
      -webkit-text-stroke: 1.5px #B3F700;
      color: transparent !important;
    }
  }
}

.banner-anim {
  h1 {
    display: inline-flex;
    position: relative;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 60px rgba(94, 255, 0, 0.2);
  }

  span {
    display: inline-block;
    -webkit-text-stroke: 1px rgba(106, 255, 0, 0.2);
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding: 0;
    position: relative;
  }

  @media(min-width:1024px) {
    span::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: inline-block;
      overflow: hidden;
      width: 0%;
      white-space: pre;
      content: attr(data-text);
      -webkit-text-stroke: rgba(93, 253, 0, 0);
      will-change: width;
      animation: grow 6s cubic-bezier(0.86, 0, 0.07, 1) 2.15s infinite alternate both;
      background: linear-gradient(to bottom, rgb(255, 255, 255) 0%,rgb(255, 255, 255) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  @media(max-width:1023px) {
    span {
      -webkit-text-fill-color:#ffffff;
    }
  }
  
 
  @keyframes grow {
    0%,
    10%,
    90%,
    100% {
      width: 0%;
    }
    50% {
      width: 100%;
    }
  }    
}