@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import "~bootstrap/scss/bootstrap";
@import 'bootstrap/dist/css/bootstrap.min.css';
*{
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
  }
body{
  overflow-x: hidden;
  padding-top:85px;
}
img{
  width: 100%;
}
.fw-bold {
  font-weight: bold;
}
.modal-title {
  font-weight: bold;
}
.modal-body {
  max-height: 500px;
  overflow: auto;
  p, div {
    font-family: "Space Mono", monospace;
    font-size: 14px;
 }
 a {
  text-decoration: none;
 }
}
.partnerBackround{
  background-image: url("../src//assets//image/banner/partnership\ 1.png");
  background-repeat: no-repeat;
  background-size:cover;
  width: 100%;
  height: 100vh;
  color: #fff;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .partnerTitle{
    font-size: 50px;
    margin-bottom: 50px;
  }
}

@media(min-width:1024px) {
  .mobile-img {
    width: 90%;
  }
}


@media(max-width:991px) {
  .navbar {
    position: fixed;
    top:85px;
    left: -100%;
    width:100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    transition: left 0.2s ease;
    z-index: 2;
    align-items: flex-start;
    padding-top: 40px;
    &.show {
      left: 0;
    }

    .nav-link {
      line-height: 30px !important;
      border-bottom: 1px solid #3f3f3f;
      padding: 30px 16px;
    }
    .navbar-nav {
      gap: 0 !important;
    }
  }
  .mobile-nav {
    background: #000000;
    width: 100%;
    height: 85px;
    min-height: 85px;
    position: fixed;
    top: 0;
    z-index: 2;
    padding: 24px 10px;    
    align-items: center;
    display: flex;
  }
  .navbar-toggle {
    padding:0;
    cursor: pointer;
    color: #fff;
    z-index: 111;
    color: #fff;
    margin-left: 13px;
    img, svg {
      vertical-align: middle;
      font-size: 24px;
      fill:#ffffff;
      z-index: 2;
  }
  }
  .mobile-logo {
    margin-left: 134px;
    width: 47px;
  }
  .banner-rhs-img, .banner {
    min-height: 300px !important;
  }
  .banner-rhs-img {
    z-index: -1;
    min-height: 370px !important;
    margin-top: -169px;
    /* z-index: -1; */
    position: relative;
  }
  .banner-content {
    padding:60px 0 0px !important;
    z-index: 1;
   }
  
  .top-btn {
    display: block;
    margin-left: auto;
  }
  .banner-lhs {
    padding-top: 16px !important;
    padding-left: 20px !important;
  }
  .welcome-txt {
    font-size: 42px !important;
    line-height: 53px !important;
  }
  .text-large {
    font-size:42px !important;
  }
  .home-section {
    padding: 0px 15px 100px !important;
  }
  .token-val, .token-label {
    color: #ffffff !important;
  }
  .mobile-img {
    width: 100%;
  }
}


@media(max-width: 767px){
  .custom-navbar {
   width: 100vw;
   .navbar-brand {
     width:105px;
   }
 }

 .copy p {   
     font-size: 10px !important;
     letter-spacing: 2px !important;
     padding: 10px 10px;
     word-break: break-all;
 }
 .main{
   padding: 440px 0 0 !important;
 }
 h1.heading-txt {
   font-size: 40px;
 }
 .banner-owl-wrapper {
   width: 300px;
 }
 .blinking-glow {
   left: -143px;
   top: 126%;
 }
 .book-wrap {
   margin-top: -83px;
 }
 .title-wrapper:before {
   left: 72px;
 }
 .custom-navbar .navbar-brand {
   margin: 0 auto;
 }
 .mobile-btn {
  display: block;
  padding: 18px 24px !important;
  font-size: 18px !important;
 }
 .partnerBackround {
  height: auto !important;
 }

}
